<template>
  <div class="upload-container">
    <Upload
      multiple="multiple"
      type="drag"
      accept=".jpg, .jpeg, .png, .gif, .svg"
      :headers="{
        authorization: 'authorization-text',
      }"
      :before-upload="beforeUpload"
      :default-file-list="fileList"
      :on-remove="handleRemove"
      :on-success="handleSuccess"
      :action="`${GLOBAL.UPLOAD_URL}/oss/upload`"
    >
      <Button icon="el-icon-upload" type="primary">上传图片</Button>
    </Upload>
  </div>
</template>

<script>
export default {
  name: 'editorSlideUpload',
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '#1890ff',
    },
    fileList: {
      default: () => [],
    },
    onImageChange: Function,
  },
  data() {
    return {
      listObj: {},
      // fileList: [],
      fileArr: [],
      dataObj: {
        policy: '',
        signature: '',
        key: '',
        ossaccessKeyId: '',
        dir: '',
        host: '',
      },
      fileCount: 0,
      minioUploadUrl: process.env.BASE_API + 'minio/upload',
    };
  },
  methods: {
    checkAllSuccess() {
      return Object.keys(this.listObj).every((item) => this.listObj[item].hasSuccess);
    },
    handleSubmit() {
      const arr = Object.keys(this.listObj).map((v) => this.listObj[v]);
      console.log(arr);

      if (!this.checkAllSuccess()) {
        this.$Message.error('请等待所有图片上传成功 或 出现了网络问题，请刷新页面重新上传！');
        return;
      }
      this.$emit('successCBK', arr);
      this.listObj = {};
      // this.fileList = [];
    },
    handleSuccess(response, file) {
      console.log(response, file);
      const uid = file.uid;
      if (file.status === 'finished' && file.percentage === 100) {
        this.listObj[uid] = {
          url: response,
          hasSuccess: true,
          width: this.width,
          height: this.height,
        };
        const { name, size } = file;
        this.fileArr.push({ name, size, url: response, uid });
        if (this.fileArr.length === this.fileCount) {
          this.onImageChange(this.fileArr);
          this.fileCount = 0;
        }
      }
      // const objKeyArr = Object.keys(this.listObj);
      // for (let i = 0, len = objKeyArr.length; i < len; i++) {
      //   if (this.listObj[objKeyArr[i]].uid === uid) {
      //     this.listObj[objKeyArr[i]].url = response;
      //     this.listObj[objKeyArr[i]].hasSuccess = true;
      //     return;
      //   }
      // }
    },
    handleRemove(file) {
      const uid = file.uid;
      const objKeyArr = Object.keys(this.listObj);
      for (let i = 0, len = objKeyArr.length; i < len; i++) {
        if (this.listObj[objKeyArr[i]].uid === uid) {
          delete this.listObj[objKeyArr[i]];
          return;
        }
      }
    },
    beforeUpload(file) {
      const fileName = file.uid;
      this.listObj[fileName] = {};
      this.listObj[fileName] = { hasSuccess: false, uid: file.uid, width: this.width, height: this.height };
      this.fileCount++;
      return true;
    },
  },
};
</script>

<style lang="less" scoped>
.upload-container .editor-slide-upload {
  margin-bottom: 20px;
}
</style>
