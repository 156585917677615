<template>
  <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="80">
    <Form-item label="标题" prop="title">
      <Input v-model="formValidate.title" placeholder="请输入标题"></Input>
    </Form-item>
    <Form-item label="副标题" prop="subTitle">
      <Input v-model="formValidate.subTitle" placeholder="请输入副标题"></Input>
    </Form-item>
    <Form-item label="封面" prop="pic">
      <form-image :fileList="[{ name: '封面', url: formValidate.pic }]" :onImageChange="handleUploadChange2" />
    </Form-item>
    <Form-item label="描述" prop="desc">
      <Input v-model="formValidate.desc" type="textarea" placeholder="请输入描述"></Input>
    </Form-item>
    <Form-item label="资料" prop="desc">
      <tinymce :width="595" :height="300" v-model="formValidate.html"></tinymce>
    </Form-item>
    <Form-item label="相册" prop="pics">
      <form-image :multiple="true" :fileList="formValidate.pics" :onImageChange="handleUploadChange" />
    </Form-item>
    <Form-item>
      <Button type="primary" @click="handleSubmit('formValidate')">提交</Button>
      <Button @click="handleReset('formValidate')" style="margin-left: 8px">重置</Button>
    </Form-item>
  </Form>
</template>

<script>
import Tinymce from '@/components/Tinymce';
import FormImage from '@/components/KUpload';
import { Create, Detail, Update } from '@/api/pms';

export default {
  data() {
    return {
      formValidate: {
        _id: null,
        title: '',
        subTitle: '',
        pics: [],
        desc: '',
        html: '',
      },
      ruleValidate: {
        title: [{ required: true, message: '标题不能为空', trigger: 'blur' }],
      },
    };
  },
  computed: {
    isEdit() {
      return !!this.$route.query.id;
    },
  },
  created() {
    if (this.$route.query.id) {
      Detail(this.$route.query.id).then((res) => {
        console.log(res);
        if (res.success) {
          this.formValidate = res.data;
        }
      });
    }
  },
  components: {
    Tinymce,
    FormImage,
  },
  methods: {
    handleUploadChange(pics) {
      this.formValidate.pics = pics;
    },
    handleUploadChange2(pics) {
      this.formValidate.pic = pics[0].url;
    },
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          if (this.isEdit) {
            Update(this.formValidate).then((res) => {
              if (res.success) {
                this.$Message.success('提交成功!');
              }
            });
          } else {
            Create(this.formValidate).then((res) => {
              if (res.success) {
                this.$Message.success('提交成功!');
              }
            });
          }
        } else {
          this.$Message.error('表单验证失败!');
        }
      });
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
  },
};
</script>

<style></style>
