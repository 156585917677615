<template>
  <div class="upload-container">
    <Button icon="el-icon-upload" type="primary" @click="dialogVisible = true">上传图片</Button>

    <Modal v-model="dialogVisible" @on-ok="handleSubmit" @on-cancel="dialogVisible = false">
      <Upload
        multiple
        type="drag"
        accept=".jpg, .jpeg, .png, .gif, .svg"
        :headers="{
          authorization: 'authorization-text',
        }"
        :default-file-list="fileList"
        :on-remove="handleRemove"
        :on-success="handleSuccess"
        :action="`${GLOBAL.UPLOAD_URL}/oss/upload`"
      >
        <div style="padding: 20px 0">
          <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
          <p>Click or drag files here to upload</p>
        </div>
      </Upload>
    </Modal>
  </div>
</template>

<script>
export default {
  name: 'editorSlideUpload',
  props: {
    color: {
      type: String,
      default: '#1890ff',
    },
  },
  data() {
    return {
      dialogVisible: false,
      listObj: {},
      fileList: [],
      dataObj: {
        policy: '',
        signature: '',
        key: '',
        ossaccessKeyId: '',
        dir: '',
        host: '',
      },
      minioUploadUrl: process.env.BASE_API + 'minio/upload',
    };
  },
  methods: {
    checkAllSuccess() {
      return Object.keys(this.listObj).every((item) => this.listObj[item].hasSuccess);
    },
    handleSubmit() {
      const arr = Object.keys(this.listObj).map((v) => this.listObj[v]);
      console.log(arr);

      if (!this.checkAllSuccess()) {
        this.$Message.error('请等待所有图片上传成功 或 出现了网络问题，请刷新页面重新上传！');
        return;
      }
      this.$emit('successCBK', arr);
      this.listObj = {};
      this.fileList = [];
      this.dialogVisible = false;
    },
    handleSuccess(response, file) {
      console.log(response, file);
      const uid = file.uid;
      if (file.status === 'finished' && file.percentage === 100) {
        this.listObj[uid] = {
          url: response,
          hasSuccess: true,
          width: this.width,
          height: this.height,
        };
      }
      // const objKeyArr = Object.keys(this.listObj);
      // for (let i = 0, len = objKeyArr.length; i < len; i++) {
      //   if (this.listObj[objKeyArr[i]].uid === uid) {
      //     this.listObj[objKeyArr[i]].url = response;
      //     this.listObj[objKeyArr[i]].hasSuccess = true;
      //     return;
      //   }
      // }
    },
    handleRemove(file) {
      const uid = file.uid;
      const objKeyArr = Object.keys(this.listObj);
      for (let i = 0, len = objKeyArr.length; i < len; i++) {
        if (this.listObj[objKeyArr[i]].uid === uid) {
          delete this.listObj[objKeyArr[i]];
          return;
        }
      }
    },
    beforeUpload(file) {
      const fileName = file.uid;
      this.listObj[fileName] = {};
      this.listObj[fileName] = { hasSuccess: false, uid: file.uid, width: this.width, height: this.height };
      console.log('========1111', file, file.uid, this.listObj);

      return true;
    },
  },
};
</script>

<style lang="less" scoped>
.upload-container .editor-slide-upload {
  margin-bottom: 20px;
}
</style>
